//Copyright by Chaafo pvt ltd
//Categories carousel is designed to show all the categories in a rolling carousel                


import React, { useState, useEffect, useContext } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import ProductBox from '../home/ProductBox';
import { CAT_N1 } from '../../App';
import GlobalContext_ from '../../GlobalContext';


function CategoriesCarousel() {

  // Category global context used
  const {cat_n1, scrollToCategoryId, setScrollToCategoryId} = useContext(GlobalContext_);
 
  return (

    <OwlCarousel nav loop {...options} className="owl-carousel-category owl-theme"  >

      {(cat_n1?.map((cat_n1, index) =>

        <div>
          <ProductBox
            className="item"
            boxClass='osahan-category-item'
            title={cat_n1.category_name}
            image={cat_n1.photo_url}
            imageClass='img-fluid'
            imageAlt={cat_n1.photo_url}
            cat_n1={cat_n1}
            onClick={()=>{}}
          />
        </div>
      ))}
    </OwlCarousel>
  );
}


const options = {
  responsive: {
    0: {
      items: 3,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
    1200: {
      items: 8,
    },
  },
  loop: true,
  lazyLoad: true,
  autoplay: true,
  dots: false,
  autoplaySpeed: 800,
  autoplayTimeout: 1000,
  // autoplayHoverPause: true,
  nav: true,
  navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
}

export default CategoriesCarousel;