//Copyright by Chaafo pvt ltd
//Detailmodal is designed to show item details when clicked on any item name

import React, { useState, useContext, useEffect } from 'react';
import { Modal, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ItemDetail from '../common/ItemDetail';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function Detailmodal(props) {
    const {MK} = useContext(GlobalContext_);
    const [itemdetail, setItemdetail] = useState('');
    const [price, setPrice] = useState([]);
    
    //Function to show item details using item id.
    useEffect(() => {
        const itemd = () => {

            if (props.show) {

                var myHeaders = new Headers();
                myHeaders.append("Cookie", "_lang=en");

                var formdata = new FormData();
                formdata.append("item_id", id);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(urls.ItemDetails+"?merchant_keys=" + MK, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setItemdetail(result.details?.data);
                        setPrice(result.details?.data?.prices)
                    })
            }
        }

        itemd();
    }, [props.show,MK])

    const getQty = ({ id, quantity }) => {
       
    }
    var id = localStorage.getItem('id');
    
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered
        >
            <Modal.Header closeButton={true}>
                <Modal.Title as='h5' id="add-address">{itemdetail.item_name} </Modal.Title>
            </Modal.Header>

            {price.length > 1 ?
                <Modal.Body>
                    <Image style={{ width: '245px', height: '200px' }} src={itemdetail.photo} alt={itemdetail.item_name} />
                    {(price?.map(price =>
                        <ItemDetail
                            id={itemdetail.item_id}
                            title={itemdetail.item_name}
                            subTitle={price.size}
                            price={price.price}
                            priceUnit='₹'
                            getValue={getQty}
                            size_id={price.size_id}
                            qty={props.quantity}
                        />
                    ))}
                </Modal.Body>
                :
                <Modal.Body>
                    {(price?.map(price =>
                        <ItemDetail
                            id={itemdetail.item_id}
                            title={itemdetail.item_name}
                            imageAlt={itemdetail.photo}
                            image={itemdetail.photo}
                            imageClass='imgheight'
                            subTitle='Indian • American • Italian'
                            price={price.price}
                            priceUnit='₹ '
                            rating={itemdetail.ratings}
                            getValue={getQty}
                            qty={props.quantity}
                        />
                    ))}

                </Modal.Body>
            }
        </Modal>
    );
}

export default Detailmodal;