//Copyright by Chaafo pvt ltd
//FooterCustom is designed to make customized pages of footer i.e. about us, privacy policy page

import React, { useState, useContext, useEffect } from 'react';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';
import { useLocation } from 'react-router-dom';

const FooterPagesContent = () => {
    const { MK, restaurantinfo, pages } = useContext(GlobalContext_);
    const location = useLocation(); // Retrieving the current url
    const [pagedata, setPageData] = useState('');
    var pageid = sessionStorage.getItem("page_id");

    const FooterArray = [
        { pathname: "AboutUs", pageid: 8 },
        { pathname: "PrivacyPolicy", pageid: 13 },
        { pathname: "Terms&Conditions", pageid: 10 },
        { pathname: "DataPolicy", pageid: 11 },
        { pathname: "RefundPolicy", pageid: 12 },
    ];

    useEffect(() => {
        const PageId = FooterArray.find(object => object.pathname === location.pathname.replace(/\//g, '')).pageid;
        sessionStorage.setItem("page_id", PageId)
    }, [])
    
    const myStyle = {
        height: '25vh',
        backgroundColor: 'black',
    };

    //Function to scroll the tab to top
    useEffect(() => {
        const scrollTotop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        scrollTotop();
    }, [MK])

    //Function to fetch the data of pages
    useEffect(() => {
        const callpagedata = () => {
            fetch(urls.PagebyId + "?merchant_keys=" + MK + "&page_id=" + pageid)
                .then(response => response.json())
                .then(result => {
                    setPageData(result.details.data);
                });
        }
        callpagedata();
    }, [MK, pageid])

    return (
        <>
            <section>
                <div style={myStyle} className="p-5 d-flex justify-content-center align-items-center ">
                    <h1 style={{ color: 'white' }}>{pagedata.title}</h1>
                </div>
                <div className='bg-white'>
                    <h2 className='pl-5 mb-3'>{pagedata.subtitle}</h2>
                    <div dangerouslySetInnerHTML={{ __html: pagedata.content }} className='pl-5 mb-3'></div>
                </div>
            </section>
        </>

    )
}

export default FooterPagesContent;