// Copyright by Chaafo pvt ltd
// Login file 

import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import FontAwesome from './common/FontAwesome';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookLogin from 'react-facebook-login';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';
import { Helmet } from 'react-helmet';

function Login() {
	const { MK, restaurantinfo } = useContext(GlobalContext_);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [merchant, setMerchant] = useState('');
	const history = useHistory();
	const [passwordShown, setPasswordShown] = useState(false);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			login();
		}
	}

	toast.configure();

	async function Merchant() {
		var T = localStorage.getItem("tokenn");

		var myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");

		var formdata = new FormData();
		formdata.append("token", T);

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		fetch(urls.UserProfile + "?merchant_keys=" + MK, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.code === 1) {
					setMerchant(result.details.data)
				} else {
					alert("Not logged in");
				}
			})
	}

	async function Loadcart() {
		var T = localStorage.getItem("tokenn");

		if (T) {
			const myHeaders = new Headers();
			myHeaders.append("Cookie", "_lang=en");

			const formdata = new FormData();
			formdata.append("device_id", T);
			formdata.append("token", T);

			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			};

			try {
				const response = await fetch(`${urls.LoadCart}?merchant_keys=${MK}`, requestOptions);
				const result = await response.json();

				if (result.code === 1) {
					return result.details.data.item; // Return server cart data
				} else {
					return []; // Return an empty array if cart is empty
				}
			} catch (error) {
				console.error('Loadcart Fetch Error:', error); // Log fetch error if any
				return []; // Return an empty array on fetch error
			}
		} else {
			const raw = localStorage.getItem('cart');
			const cartFetchedFromLocalStorage = JSON.parse(raw);
			return cartFetchedFromLocalStorage || []; // Return local cart data or empty array
		}
	}

	const mergeCarts = (localCart, serverCart) => {

		const mergedCart = [...serverCart];

		localCart.forEach(localItem => {

			const existingItemIndex = mergedCart.findIndex(serverItem =>
				serverItem?.item_id === localItem.item_id && serverItem?.size_id === localItem.size_id
			);

			if (existingItemIndex !== -1) {
				mergedCart[existingItemIndex].qty += localItem.qty;
			} else {
				mergedCart.push(localItem);
			}
		});
		return mergedCart;
	};

	const updateServerCart = async (mergedCart) => {

		const T = localStorage.getItem("tokenn");
		const myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");

		const formdata = new FormData();
		mergedCart.forEach(item => {
			formdata.append("item_id", item.item_id);
			formdata.append("price", item.price);
			formdata.append("qty", item.qty);
			formdata.append("size", item.size);
		});

		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		const response = await fetch(`${urls.Add_Delete_Item}?merchant_keys=${MK}&device_id=${T}&device_platform=web&transaction_type=pickup&lang=en&token=${T}`, requestOptions);
		const result = await response.json();
	};

	const handleLogin = async () => {
		const localCart = JSON.parse(localStorage.getItem('cart')) || [];

		const serverCart = await Loadcart(); // Get server cart data

		const mergedCart = mergeCarts(localCart, serverCart); // Merge local and server carts

		await updateServerCart(mergedCart); // Update the server with the merged cart data

		// Update the local state with the merged cart data
		localStorage.setItem('cart', JSON.stringify(mergedCart));
	};

	async function login() {
		var myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");

		var formdata = new FormData();
		formdata.append("username", username);
		formdata.append("password", password);

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		let result = await fetch(urls.Login + "?merchant_keys=" + MK, requestOptions);
		result = await result.json();
		localStorage.setItem("tokenn", result.details.token);

		if (result.code == 1) {
			await handleLogin(); // Handle cart merging after login
			localStorage.removeItem("cart");
			window.location.reload(true);
			Merchant();
			history.push('/');
		}
		else {
			toast.error(result.msg, {
				position: "top-center",
				autoClose: 400
			});
			history.push("/login")
		}
	}

	return (
		<>
			<Helmet>
				<meta name="robots" content="index, follow"></meta>
				<link rel="canonical" href="https://kamalchickencorner.com/login"></link>{/* this needs to be set dynamically */}

				<meta name="description" content="Login to your Kamal Chicken account to order food, view past orders, and manage your profile. Enjoy a seamless chicken ordering experience." />
				<title>Login to Your Account | {restaurantinfo?.merchant_name || 'Kamal Chicken'}</title>
				<meta property="og:title" content={`Login to Your Account | ${restaurantinfo.merchant_name}`}></meta>
				<meta property="og:description" content="Login to your Kamal Chicken account to order food, view past orders, and manage your profile. Enjoy a seamless chicken ordering experience."></meta>
				<meta property="og:type" content="website"></meta>
				<meta property="og:url" content="https://kamalchickencorner.com/login"></meta> {/* this needs to be set dynamically */}
				<meta name="keywords" content={'login, user account, order chicken online, account access, chicken delivery login'} />{/* this needs to be set dynamically */}
			</Helmet>

			<Container fluid className='bg-white'>
				<Row>
					<Col md={4} lg={6}>
						<div>
							<img alt={restaurantinfo.merchant_name} className="login12" src={restaurantinfo.merchant_image} ></img>
						</div>
					</Col>
					<Col md={8} lg={6}>
						<div className="login d-flex align-items-center py-5">
							<Container>
								<Row>
									<Col md={9} lg={8} className="mx-auto pl-5 pr-5">
										<h3 className="login-heading mb-4">Welcome back!</h3>
										<Form>
											<div className="form-label-group">
												<Form.Control type="text" onChange={(e) => setUsername(e.target.value)} id="inputEmail" placeholder="Username" />
												<Form.Label htmlFor="inputEmail">Email address / Mobile</Form.Label>
											</div>
											<div className="form-label-group">
												<Form.Control type={passwordShown ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} id="inputPassword" placeholder="Password" />
												<Form.Label htmlFor="inputPassword">Password
													{passwordShown ?
														<div className='cursorPoint'><i className="icofont-eye-blocked float-right" onClick={togglePassword}></i></div> : <div className='cursorPoint'><i className="icofont-eye float-right" onClick={togglePassword}></i></div>}
												</Form.Label>
											</div>
											<div>
												<p className='mb-3 btn-link text-right'><Link className='text-right' to={'/forgot'}>Forgot password</Link></p></div>
											<Link to="/" type='submit' onKeyPress={handleKeyPress} className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" onClick={login}>Sign in</Link>

											<div className="text-center pt-3">
												Don’t have an account? <Link className="font-weight-bold" to="/register">Sign Up</Link>
											</div>

											<hr className="my-4" />
											<p className="text-center">LOGIN WITH</p>
											<div className="row">
												<div className="col pr-2">
													<Button className="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase" type="submit"><FontAwesome icon="google" className="mr-2" /> Google</Button>
												</div>
												<div className="col pl-2">
													<Button className="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase" type="button"><FontAwesome icon="facebook" className="mr-2" /> Facebook</Button>
												</div>
											</div>
										</Form>
									</Col>
								</Row>
							</Container>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Login;
